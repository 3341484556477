<template>
    <div class="modal-dropout">
        <Loading :loading="loading" />
        <div class="head flex-between items-start">
            <div class="title" v-html="$translate('DROPOUT')" />
            <div class="reason" v-html="$translate('DROPOUT_REASON')" />
        </div>
        <div class="body">
            <div
                v-for="item in reasons"
                @click="$set(item, 'checked', !item.checked)"
                class="item flex-row items-center"
                :key="item.value"
            >
                <Checkbox class="flex-wrap" v-model="item.checked" />
                <span v-html="item.label" />
            </div>
        </div>
        <div class="buttons">
            <div class="flex-row">
                <button
                    @click="btn.action"
                    :key="idx"
                    v-for="(btn, idx) in buttons"
                    :disabled="loading"
                    :class="btn.class"
                >
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ModalDropout',
    props: ['options'],
    data: () => ({
        reasons: [],
        loading: false,
    }),
    created() {
        this.initReasons()
    },
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        buttons() {
            return [
                {
                    label: 'CANCEL',
                    class: 'btn-default',
                    action: () => this.$emit('close'),
                },
                {
                    label: 'CONFIRM',
                    class: 'btn-default',
                    action: () => this.dropout(),
                },
            ]
        },
    },
    methods: {
        initReasons() {
            this.reasons = [
                { label: '어떻게 서비스를 이용하는지 잘 모르겠어요.', value: 'A' },
                { label: '주선자에게 소개팅을 부탁하기 민망해요.', value: 'B' },
                { label: '제 신상이 노출될 것 같아요.', value: 'C' },
                { label: '남자/여자 친구를 만났어요!', value: 'D' },
                { label: '주선자에게 신뢰가 가지 않아요.', value: 'E' },
                { label: '원래 호기심에 가입했고 진지하게 이용할 생각은 없었어요.', value: 'F' },
                { label: '기타', value: 'G' },
            ]
        },
        async dropout() {
            const selected = this.reasons.filter(item => item.checked).map(r => r.value)
            if (selected.length > 0) {
                try {
                    this.loading = true
                    const ret = await userService.dropout(selected)
                    if (ret.success) {
                        this.$toast.success(ret.msg)
                        this.$store.dispatch('signOut')
                    } else {
                        this.$toast.error(ret.msg)
                    }
                } catch (e) {
                    this.$toast.error(e.data)
                } finally {
                    this.loading = false
                }
            } else {
                this.$toast.error('SELECT_REASON')
            }
            this.$emit('close')
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.dropout()
            }
        },
    },
}
</script>
